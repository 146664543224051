import { Routes } from '@angular/router';
import { OffersComponent } from './offers.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { RequestOfferComponent } from './request-offer/request-offer.component';
import { RequestOffer2Component } from './request-offer2/request-offer2.component';

export const OFFERS_ROUTES: Routes = [
    {
        path: 'request', component: OffersComponent, canActivate: [AuthGuard], children: [
            { path: 'event', component: RequestOfferComponent }
        ]
    },
    {
      path: 'request2', component: OffersComponent, canActivate: [AuthGuard], children: [
        { path: 'event', component: RequestOffer2Component }
    ]
    }
];
