import { Component, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { EventOffer } from "src/app/shared/models/EventOffer";
import { OffersService } from "../shared/offers.service";
import { EventCost } from "src/app/shared/models/EventCost";
import { forkJoin, switchMap } from "rxjs";
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { setTime } from "@progress/kendo-angular-dateinputs/util";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
import { JwtHelperService } from "@auth0/angular-jwt";
import { LOCAL_STORAGE } from "src/app/core/constants";
import { NgbDateStruct, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import * as moment from "moment";
import { AccountService } from "src/app/account/shared/account.service";
import { Country } from "src/app/shared/models/Country";
import { Village } from "src/app/shared/models/Village";
import { EventOfferDraft } from "src/app/shared/models/EventOfferDraft";
import { Company } from "src/app/shared/models/Company";
import { DateRangePopupComponent, MultiViewCalendarComponent } from "@progress/kendo-angular-dateinputs";
import { DeliveryAddress } from "src/app/shared/models/DeliveryAddress";
import { FormControl, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-request-offer2",
  templateUrl: "./request-offer2.component.html",
  styleUrl: "./request-offer2.component.css",
  animations: [
    trigger("fade", [
      state("visible", style({ opacity: 1 })),
      state("hidden", style({ opacity: 0 })),
      transition("visible <=> hidden", animate("1s ease-in-out")),
    ]),
  ],
})
export class RequestOffer2Component implements OnInit {
  private _tokenData: any;

  public currentDraft: EventOfferDraft = new EventOfferDraft();
  public userDrafts: EventOfferDraft[] = [];
  public eventOffer: EventOffer = new EventOffer();

  public deliveryAddress: DeliveryAddress = new DeliveryAddress();

  public cableProductsAmount = {
    one: 0,
    five: 0,
    ten: 0,
    fifteen: 0,
    twenty: 0,
    thirty: 0,
    fifty: 0,
  };

  public pickupPoints: any[] = [];

  public eventStartDate;
  public eventEndDate;

  public selectionDateRange = {
    start: null,
    end: null,
  };

  public showHardware: boolean = false;
  public hasHardware: boolean = false;
  public hasSoftware: boolean = false;
  public hasService: boolean = false;

  public showSoftware: boolean = false;
  public showMainDevice: boolean = false;
  public showServices: boolean = false;
  public showOnlinePay: boolean = false;
  public showRenting: boolean = false;

  public softwarePanelOpen: boolean = false;
  public servicePanelOpen: boolean = false;
  public hardwarePanelOpen: boolean = false;

  private _currentVat: number;

  public currentLanguage: string;

  public offerValid: boolean = false;
  public acceptAGB: boolean = false;

  public hasData: boolean = false;

  public clientCompany: Company = null;

  public isNewDraft: boolean = true;

  public closeDraft: EventOfferDraft = null;

  public allCountries: Country[] = [];

  public selectedCountry = { id: 0, name: "Select Country" };

  public deliveryForm: FormGroup = new FormGroup({
    address: new FormControl("", Validators.required),
    addressDetails: new FormControl(),
    country: new FormControl("", Validators.required),
    postcode: new FormControl("", Validators.required),
    phone: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    province: new FormControl("", Validators.required),
  });

  @ViewChild("eventOfferModal", { static: true }) eventOfferModal: any;

  @ViewChild("closeDraftModal", { static: true }) closeDraftModal: any;

  @ViewChild("deliveryModal", { static: true }) deliveryModal: any;

  constructor(
    private _translate: TranslateService,
    private _offersService: OffersService,
    private _toastr: ToastrService,
    private _router: Router,
    private _jwtHelper: JwtHelperService,
    private _accountService: AccountService,
    private _modalService: NgbModal
  ) {
    this.currentLanguage = this._translate.currentLang;
  }

  public eventDuration = 0;

  public showVillageFraction = false;
  public showGeneralLocationInput = false;

  public chosenVillage: Village;
  public chosenCountry: Country;

  public currentClient: any;

  ngOnInit() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    this._tokenData = this._jwtHelper.decodeToken(accessToken).data;
    this._offersService.getPickupPoints().subscribe((result) => {
      this.pickupPoints = result.filter((p) => p.id !== 2);
    });

    this._offersService.getAllDrafts().subscribe((result) => {
      this.userDrafts = result;
      if (this.userDrafts.length > 0) {
        this.currentDraft = this.userDrafts[0];
        this.hasData = true;
        this.isNewDraft = false;
        this.initializeDraft();
      } else {
        this.newDraft();
      }

    },
    error => {
      if (error.status === 610) {
        this.currentDraft.language = this.currentLanguage;
        if (!this.currentDraft.pickupPoint) {
          this.currentDraft.pickupPoint = this.pickupPoints[0];
        }
      }
    });

    this._accountService.getCompanyByClientId(this._tokenData.id).subscribe((result) => {
      this.clientCompany = result;
    });

    this._offersService.getCountries().subscribe((result) => {
      this.allCountries = result;
    });

    this._accountService.getSpecificClientById(this._tokenData.id).subscribe((result) => {
      this.currentClient = result;
    });
  }

  newDraft() {
    this.currentDraft = new EventOfferDraft();
    this.eventOffer = new EventOffer();
    this.currentDraft.language = this.currentLanguage;
    this.currentDraft.pickupPoint = this.pickupPoints[0];
    this.selectionDateRange.start = null;
    this.selectionDateRange.end = null;
    this.showHardware = false;
    this.showSoftware = false;
    this.showServices = false;
    this.showRenting = false;
    this.hasHardware = false;
    this.hasSoftware = false;
    this.hasService = false;
    this.softwarePanelOpen = false;
    this.servicePanelOpen = false;
    this.hardwarePanelOpen = false;
    this.offerValid = false;
    this.acceptAGB = false;
    this.hasData = false;
    this.isNewDraft = true;
  }

  initializeDraft() {
    if (
      this.currentDraft.startDate !== "Invalid date" ||
      this.currentDraft.endDate !== "Invalid date"
    ) {
      this.selectionDateRange.start = new Date(this.currentDraft.startDate);
      this.selectionDateRange.end = new Date(this.currentDraft.endDate);
    }

    this.currentDraft.language = this.currentLanguage;

    if (!this.currentDraft.pickupPoint) {
      this.currentDraft.pickupPoint = this.pickupPoints[0];
    }

    this.onDateChange(false);
    this.checkSoftware();
    this.checkHardware();
    this.checkService();

    if (this.hasSoftware) {
      this.continue("software", false);
      this.showSoftware = true;
    }

    if (this.hasHardware) {
      this.continue("hardware", false);
      this.showHardware = true;
    }

    if (this.hasService) {
      this.continue("service", false);
    }

    this.calculateEventOfferPrice();
  }

  calculateEventOfferPrice() {
    this.draftToOffer();

    this._offersService
      .calculateEventOfferPrice(
        this.eventOffer,
        this.eventDuration,
        this.currentDraft.pickupPoint.id,
        true
      )
      .subscribe((result) => {
        this._currentVat = result.vat;
        this.eventOffer.vat = this._currentVat * 100;
        this.eventOffer.fullPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discountedPrice = EventCost.fromDtoPrice(result);
        this.eventOffer.discount = 0;
        this.eventOffer.insuranceCost = result.insuranceCost;
        this._calculateVat();
      });
  }

  private _calculateVat() {
    const vatPrice: number =
      (this.eventOffer.discountedPrice / 100) * this.eventOffer.vat;
    this.eventOffer.discountedPriceWithVat =
      parseFloat(this.eventOffer.discountedPrice.toString()) +
      parseFloat(vatPrice.toString());
  }

  /**
   * Check if current draft contains any Software
   */

  checkSoftware() {
    if (
      this.currentDraft.softwareWaiter > 0 ||
      this.currentDraft.softwareCashRegister > 0 ||
      this.currentDraft.softwareMainDevice > 0 ||
      this.currentDraft.softwarePrinter > 0 ||
      this.currentDraft.vivaWalletLicenses > 0 ||
      this.currentDraft.sumUpLicenses > 0
    ) {
      this.hasSoftware = true;
    } else {
      this.hasSoftware = false;
    }
    if (this.currentDraft.softwareWaiter > 99) {
      this.currentDraft.softwareWaiter = 99;
    }
    if (this.currentDraft.softwareCashRegister > 99) {
      this.currentDraft.softwareCashRegister = 99;
    }
    if (this.currentDraft.softwarePrinter > 99) {
      this.currentDraft.softwarePrinter = 99;
    }
    if (this.currentDraft.vivaWalletLicenses > 99) {
      this.currentDraft.vivaWalletLicenses = 99;
    }
    if (this.currentDraft.sumUpLicenses > 99) {
      this.currentDraft.sumUpLicenses = 99;
    }
  }

  /**
   * Check if current draft contains any hardware
   */

  checkHardware() {
    if (
      this.currentDraft.hardwarePhone > 0 ||
      this.currentDraft.hardwarePrinter > 0 ||
      this.currentDraft.hardwareRouter > 0 ||
      this.currentDraft.beltBags > 0 ||
      this.currentDraft.cashDrawers > 0 ||
      this.currentDraft.waiterWallets > 0 ||
      this.currentDraft.stickers > 0
    ) {
      this.hasHardware = true;
    } else {
      this.hasHardware = false;
    }

    if (this.currentDraft.hardwarePhone > 99) {
      this.currentDraft.hardwarePhone = 99;
    }

    if (this.currentDraft.hardwarePrinter > 99) {
      this.currentDraft.hardwarePrinter = 99;
    }

    if (this.cableProductsAmount.one > 99) {
      this.cableProductsAmount.one = 99;
    }

    if (this.cableProductsAmount.five > 99) {
      this.cableProductsAmount.five = 99;
    }

    if (this.cableProductsAmount.ten > 99) {
      this.cableProductsAmount.ten = 99;
    }

    if (this.cableProductsAmount.twenty > 99) {
      this.cableProductsAmount.twenty = 99;
    }

    if (this.cableProductsAmount.thirty > 99) {
      this.cableProductsAmount.thirty = 99;
    }

    if (this.cableProductsAmount.fifty > 99) {
      this.cableProductsAmount.fifty = 99;
    }

    if (this.currentDraft.hardwareRouter > 99) {
      this.currentDraft.hardwareRouter = 99;
    }

    if (this.currentDraft.beltBags > 99) {
      this.currentDraft.beltBags = 99;
    }

    if (this.currentDraft.cashDrawers > 99) {
      this.currentDraft.cashDrawers = 99;
    }

    if (this.currentDraft.waiterWallets > 99) {
      this.currentDraft.waiterWallets = 99;
    }

    if (this.currentDraft.stickers > 99) {
      this.currentDraft.stickers = 99;
    }
  }

  /**
   * Check if current draft contains any services
   */

  checkService() {
    if (
      this.currentDraft.hasCancellationInsurance ||
      this.currentDraft.hasCheckingPriceList ||
      this.currentDraft.hasPickupInstalled ||
      this.currentDraft.hasStockManagement ||
      this.currentDraft.hasTraining
    ) {
      this.hasService = true;
    } else {
      this.hasService = false;
    }
  }

  onSoftwareLicenseQuantityChange(licenseType: string, negative?: boolean) {
    this.checkSoftware();

    switch (licenseType) {
      case "mainDevice":
        if (negative) {
          this.currentDraft.hasStockManagement = false;
          this.checkService();
        }
    }
    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  onHardwareQuantityChange() {
    this.currentDraft.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.checkHardware();

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  public onAdditionalCostNumberInputChange() {
    this.checkSoftware();
    this.checkHardware();
    this.checkService();

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  onPickupPointChange(pickupPointId: number) {
    this.currentDraft.pickupPoint = this.pickupPoints.filter(
      (pickup) => pickup.id === pickupPointId
    )[0];
    if (pickupPointId === 1) {
      this.currentDraft.delivery = false;
    } else {
      this.currentDraft.delivery = true;
    }
    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  submitEventOffer() {
    this._modalService.dismissAll();
    this._offersService
      .insertEventOffer(this.eventOffer, this._tokenData.firstEmail)
      .subscribe((result) => {
        this._offersService.closeDraft(this.currentDraft.id).subscribe((res) => {
          this._toastr.success(
            this._translate.instant("offers.request.eventSuccessRequest")
          );
          if (result.hasHardware) {
            if (this.eventOffer.isAdvertise) {
              return this._router.navigate([
                "/event/details/" + result.eventOfferId + "/advertising",
              ]);
            } else {
              return this._router.navigate(["/event/my-events"]);
            }
          } else {
            if (this.eventOffer.isAdvertise) {
              return this._router.navigate([
                "/event/details/" + result.eventId + "/advertising",
              ]);
            } else {
              return this._router.navigate([
                "/event/details/" + result.eventId + "/general",
              ]);
            }
          }
        });
      });
  }

  onDateChange(byUser: boolean) {
    if (this.selectionDateRange.start && this.selectionDateRange.end) {
      const startDate = moment(this.selectionDateRange.start);
      const endDate = moment(this.selectionDateRange.end);

      this.currentDraft.startDate = startDate.format("YYYY-MM-DD");
      this.currentDraft.endDate = endDate.format("YYYY-MM-DD");

      const today = moment().format("YYYY-MM-DD");
      if (today === this.eventOffer.startDate) {
        this.currentDraft.pickupDate = startDate.format("YYYY-MM-DD");
      } else {
        this.currentDraft.pickupDate = startDate
          .subtract(1, "day")
          .format("YYYY-MM-DD");
      }
      this.currentDraft.returnDate = endDate.add(1, "day").format("YYYY-MM-DD");

      this.eventDuration =
        Math.floor(
          (this.selectionDateRange.end - this.selectionDateRange.start) /
            86400000
        ) + 1;

      this.calculateEventOfferPrice();
      if (byUser) {
        this.saveDraft();
      }
    }
  }

  onCountryChosen(country: Country) {
    this.showVillageFraction = country?.id === 1; // Italy
    this.showGeneralLocationInput = !this.showVillageFraction;
    this.chosenCountry = country;
  }

  continue(type: "software" | "hardware" | "service", byUser: boolean) {
    switch (type) {
      case "software":
        this.showSoftware = true;
        this.softwarePanelOpen = true;
        break;
      case "hardware":
        this.showRenting = true;
        this.softwarePanelOpen = false;
        this.hardwarePanelOpen = true;
        break;
      case "service":
        this.showServices = true;
        this.hardwarePanelOpen = false;
        this.servicePanelOpen = true;

        if (byUser) {
          this.currentDraft.hasCancellationInsurance = true;
          this.currentDraft.hasCheckingPriceList = true;
          this.currentDraft.hasTraining = true;

          if (this.hasHardware) {
            this.currentDraft.hasPickupInstalled = true;
          }

          this.onAdditionalCostNumberInputChange();
        }

        this.offerValid = true;

        break;
    }
    if (byUser) {
      this.saveDraft();
    }
  }

  getCashRegistersHardware() {
    let cashRegisters =
      this.currentDraft.hardwarePhone - this.currentDraft.softwareWaiter;
    if (cashRegisters > this.currentDraft.softwareCashRegister) {
      cashRegisters = this.currentDraft.softwareCashRegister;
    }
    return cashRegisters >= 0 ? cashRegisters : 0;
  }

  getMainDeviceHardware() {
    const mainDevices =
      this.currentDraft.hardwarePhone -
      this.currentDraft.softwareWaiter -
      this.currentDraft.softwareCashRegister;
    return mainDevices > 0 ? 1 : 0;
  }

  onOnlinePaySwitch(remove: boolean) {
    if (remove) {
      this.currentDraft.vivaWalletLicenses = 0;
      this.currentDraft.sumUpLicenses = 0;
    }
  }

  onRentDevicesClick() {
    this.showHardware = true;
    this.currentDraft.hardwarePhone =
      this.currentDraft.softwareWaiter +
      this.currentDraft.softwareCashRegister +
      this.currentDraft.softwareMainDevice;
    this.currentDraft.hardwarePrinter = this.currentDraft.softwarePrinter;

    if (
      this.currentDraft.hardwarePhone > 0 ||
      this.currentDraft.hardwarePrinter > 0
    ) {
      this.hasHardware = true;
    }

    this.calculateEventOfferPrice();
    this.saveDraft();
  }

  getAdditionalCostsArr() {
    let additionalCostsArr: { id: number; quantity: number }[] = [];

    if (this.currentDraft.hasCancellationInsurance) {
      additionalCostsArr.push({ id: 1, quantity: 1 });
    }

    if (this.currentDraft.cashDrawers) {
      additionalCostsArr.push({
        id: 3,
        quantity: this.currentDraft.cashDrawers,
      });
    }

    if (this.currentDraft.beltBags > 0) {
      additionalCostsArr.push({ id: 4, quantity: this.currentDraft.beltBags });
    }

    if (this.currentDraft.waiterWallets > 0) {
      additionalCostsArr.push({
        id: 5,
        quantity: this.currentDraft.waiterWallets,
      });
    }

    if (this.currentDraft.hasPickupInstalled) {
      additionalCostsArr.push({ id: 6, quantity: 1 });
    }

    if (this.currentDraft.hasCheckingPriceList) {
      additionalCostsArr.push({ id: 7, quantity: 1 });
    }

    if (this.currentDraft.hasTraining) {
      additionalCostsArr.push({ id: 8, quantity: 1 });
    }

    if (this.currentDraft.vivaWalletLicenses > 0) {
      additionalCostsArr.push({
        id: 9,
        quantity: this.currentDraft.vivaWalletLicenses,
      });
    }

    if (this.currentDraft.sumUpLicenses > 0) {
      additionalCostsArr.push({
        id: 10,
        quantity: this.currentDraft.sumUpLicenses,
      });
    }

    if (this.currentDraft.hasStockManagement) {
      additionalCostsArr.push({ id: 11, quantity: 1 });
    }

    if (this.currentDraft.stickers > 0) {
      additionalCostsArr.push({ id: 12, quantity: this.currentDraft.stickers * 10 });
    }

    return additionalCostsArr;
  }

  draftToOffer() {
    this.eventOffer.eventName = this.currentDraft.eventName;
    this.eventOffer.startDate = this.currentDraft.startDate;
    this.eventOffer.endDate = this.currentDraft.endDate;
    this.eventOffer.pickUpDate = this.currentDraft.pickupDate;
    this.eventOffer.returnDate = this.currentDraft.returnDate;

    this.eventOffer.softwareWaiter = this.currentDraft.softwareWaiter;
    this.eventOffer.softwareCashRegister = this.currentDraft.softwareCashRegister;
    this.eventOffer.softwareMainDevice = this.currentDraft.softwareMainDevice;
    this.eventOffer.softwarePrinter = this.currentDraft.softwarePrinter;
    this.eventOffer.hardwarePhone = this.currentDraft.hardwarePhone;
    this.eventOffer.hardwarePrinter = this.currentDraft.hardwarePrinter;
    this.eventOffer.hardwareRouter = this.currentDraft.hardwareRouter;
    this.eventOffer.additionalCosts = this.getAdditionalCostsArr();
    this.eventOffer.hardwareCable = JSON.stringify(this.cableProductsAmount);
    this.eventOffer.pickupPoint = this.currentDraft.pickupPoint;
    this.eventOffer.village = this.chosenVillage;
    this.eventOffer.zone = this.currentDraft.zone;
    this.eventOffer.isAdvertise = this.currentDraft.isAdvertise;

    this.eventOffer.company = this.clientCompany;

    if (this.eventOffer.notes) {
      this.eventOffer.notes = this.eventOffer.notes.replace(/\n/g, "<br />");
    }
  }

  openOfferModal() {
    const accessToken = localStorage.getItem(LOCAL_STORAGE.ACCESS_TOKEN);
    const tokenData = this._jwtHelper.decodeToken(accessToken).data;

    this._accountService
      .getSpecificClientById(tokenData.id)
      .subscribe((result) => {
        console.log(result);
      });

    this.draftToOffer();

    this._modalService.open(this.eventOfferModal, { centered: true });
  }

  saveDraft() {
    this._offersService.saveDraft(this.currentDraft).subscribe((res) => {
      this.currentDraft = res.draft;
      if (this.isNewDraft) {
        this.isNewDraft = false;
        this.userDrafts.push(this.currentDraft);
      }
    });
  }

  chooseDraft(draft: EventOfferDraft) {
    this.currentDraft = draft;
    this.initializeDraft();
  }

  openCloseDraftModal(draft: EventOfferDraft) {
    this.closeDraft = draft;
    this._modalService.open(this.closeDraftModal, { centered: true });
  }

  clearDraft() {
    this._offersService.closeDraft(this.closeDraft.id).subscribe((res) => {
      if (this.currentDraft.id === this.closeDraft.id) {
        this.newDraft();
      }
      this.userDrafts = this.userDrafts.filter(
        (draft) => draft.id !== this.closeDraft.id
      );
      this._modalService.dismissAll();
    });
  }

  openDeliveryModal() {
    this._modalService.open(this.deliveryModal, { centered: true });
  }

  addDeliveryAddress() {
    if (!this.deliveryForm.valid || this.deliveryForm.controls.country.value.id < 1) {
      return;
    }

    this.deliveryAddress = new DeliveryAddress();
    this.deliveryAddress.address = this.deliveryForm.controls.address.value;
    this.deliveryAddress.addressDetails = this.deliveryForm.controls.addressDetails.value;
    this.deliveryAddress.country = this.deliveryForm.controls.country.value;
    this.deliveryAddress.postcode = this.deliveryForm.controls.postcode.value;
    this.deliveryAddress.phone = this.deliveryForm.controls.phone.value;
    this.deliveryAddress.city = this.deliveryForm.controls.city.value;
    this.deliveryAddress.province = this.deliveryForm.controls.province.value;
    this.deliveryAddress.firstLastName = this.currentClient.lastName + " " + this.currentClient.firstName;

    this._offersService.saveDeliveryAddress(this.deliveryAddress).subscribe((result) => {
      this.eventOffer.deliveryAddress = result;
      this._modalService.dismissAll();
      this._modalService.open(this.eventOfferModal, { centered: true });
    });

  }

}
