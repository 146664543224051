<div class="row">
  <div class="col">
    <div class="jumbotron" style="padding-top: 0px; padding-bottom: 0px;">
      <h1 class="display-4">{{'event.details.pricelist' | translate}}</h1>
      <hr class="my-4">
    </div>
  </div>
</div>
<div class="row mt-3">
  <div class="col col-pricelist-left">
    <div class="card">
      <div class="row">
        <div class="col">
          <div class="card-body">
            <div class="card-text" style="text-align: center;">
              <button class="btn btn-primary btn-sm" style="font-size: large;" (click)="changeMode('loadPriceList')">{{'price-list.dashboard.uploadPriceList' | translate}}</button>&nbsp;&nbsp;
              <button class="btn btn-primary btn-sm" style="font-size: large" (click)="changeMode('reuseOldPriceList')">{{'price-list.dashboard.reusePriceList' | translate}}</button>
            </div>
          </div>
          <!-- load pricelist-->
          <div class="card-body" *ngIf="mode === 'loadPriceList'">
            <hr />
            <div class="card-title">
              <h4 style="margin-top: 0px;">
                {{'price-list.dashboard.uploadNewPriceList' | translate}}</h4>
              <h5 style="margin-top: 0px;">
                {{'event.details.pricelistDesc' | translate}}</h5>
                <a href="assets/demo/{{demoPriceListaName}}" class="btn btn-block btn-success mt-4"
                *ngIf="demoPriceListaName"><i class="fa fa-file-excel"></i>
                {{'event.details.downloadExcelFile' | translate}}</a>
            </div>
            <div class="card-text">
              <div class="row" *ngIf="priceListUploadError">
                <div class="col">
                  <div class="alert alert-warning" role="alert" [innerHTML]="priceListUploadError"></div>
                </div>
              </div>
              <div>
                <ul>
                  <li [innerHTML]="'event.details.excelUploadStep1' | translate"></li>
                  <li [innerHTML]="'event.details.excelUploadStep2' | translate"></li>
                  <li [innerHTML]="'event.details.excelUploadStep3' | translate"></li>
                  <li [innerHTML]="'event.details.excelUploadStep4' | translate"></li>
                  <li [innerHTML]="'event.details.excelUploadStep5' | translate"></li>
                </ul>
              </div>
                <div class="col">
                  <div class="form-group" style="margin: 0;">
                    <div class="custom-file">
                      <input #priceListInput type="file" class="custom-file-input" id="customFile" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        (change)="handlePriceListFileInput($event)">
                      <!-- TODO :  -->
                      <label class="custom-file-label {{'shared.langCode' | translate}}"
                        for="customFile">{{ (!uploadedPriceListName ? ('price-list.dashboard.uploadPriceList2' | translate) : uploadedPriceListName) }}</label>
                    </div>
                  </div>
                  <button class="float-right btn btn-primary btn-sm"
                    (click)="uploadNewPriceListFile()">{{'price-list.dashboard.upload' | translate}}</button>
                  <div class="clearfix"></div>
                  <hr />
                </div>

            </div>
          </div>
          <!-- reuse old pricelist-->
          <div class="card-body" *ngIf="mode === 'reuseOldPriceList'">
            <hr />
            <div class="card-title">
              <h4 style="margin-top: 0px;">
                {{'price-list.dashboard.usePriceListDescription' | translate}}
              </h4>
            </div>
            <div class="card-text" style="text-align: center;">
              <div class="table-responsive">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col">{{'price-list.dashboard.event' | translate}}</th>
                      <th scope="col">{{'price-list.dashboard.priceListName' | translate}}</th>
                      <th scope="col">{{'price-list.dashboard.createdAt' | translate}}</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngIf="allPriceLists; else emptyAllPriceListsTemplate">
                      <tr *ngFor="let priceList of allPriceLists">
                        <ng-container *ngIf="priceList.event.id !== event.id">
                          <td>{{priceList.event.eventName}}</td>
                          <td>{{priceList.name}}</td>
                          <td style="white-space: nowrap;">{{priceList.createdAt | date:'dd-MM-yyyy'}}</td>
                          <td class="text-right" style="white-space: nowrap;">
                            <a href="price-list/preview/{{priceList.id}}" target="_blank" class="btn btn-link" style="color:#57c6f2;"><i
                              class="fa fa-eye"></i></a>&nbsp;
                            <button class="btn btn-link" style="color:green;" (click)="exportPriceList(priceList.id, priceList.name)"><i
                              class="fa fa-file-excel"></i></button>&nbsp;
                            <button type="button" class="btn btn-link btn-primary" style="font-size: 12px; white-space: nowrap;"
                              (click)="duplicatePriceList(priceList.id)">
                              {{'price-list.dashboard.useThisPriceList' | translate}}
                            </button>
                          </td>
                        </ng-container>
                      </tr>
                    </ng-container>
                    <ng-template #emptyAllPriceListsTemplate>
                      <tr>
                        <td colspan="4" class="text-center">
                          {{'price-list.dashboard.noPriceListsErrorMsg' | translate}}
                        </td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col col-pricelist-right">
    <div class="card">
      <div class="card-body">
        <div class="card-title" style="position:relative">
          <h4 style="margin-top: 0px;">{{'price-list.dashboard.allYoutPriceLists' | translate}}
          </h4>
          <div>
            {{'event.details.multiplePricelistsDesc' | translate}}
          </div>
          <div class="scroll-info">
            &rArr;&nbsp;&nbsp;Scroll
          </div>
        </div>
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">{{'price-list.dashboard.priceListName' | translate}}</th>
                <th scope="col">{{'price-list.dashboard.createdAt' | translate}}</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngIf="this.event.priceLists && this.event.priceLists.length > 0; else emptyPriceListsTemplate">
                <tr *ngFor="let priceList of this.event.priceLists">
                  <td>{{priceList.name}}</td>
                  <td>{{priceList.createdAt | date:'dd-MM-yyyy'}}</td>
                  <td class="text-right">
                    <div class="btn-group btn-group-sm" role="group" fxLayout="row" fxLayoutAlign="space-around start">
                      <!-- TODO: Export as PDF -->
                      <a href="price-list/preview/{{priceList.id}}" target="_blank" class="btn btn-link"><i
                          class="fa fa-eye"></i>
                        {{'price-list.dashboard.preview' | translate}}</a>
                      <ng-container *ngIf="priceList.event; else elseTemplate">
                        <ng-container *ngIf="priceList.event.endDate > todaysDate">
                          <a href="/price-list/edit/{{priceList.id}}" class="btn btn-link"><i class="fa fa-edit"></i>
                            {{'price-list.dashboard.edit' | translate}}</a>
                        </ng-container>
                        <button type="button" class="btn btn-link" (click)="showDeleteConfermationDialog(priceList.id)">
                          <i class="fa fa-trash"></i> {{'price-list.dashboard.delete' | translate}}</button>
                      </ng-container>
                      <ng-template #elseTemplate>
                        <a href="/price-list/edit/{{priceList.id}}" class="btn btn-link"><i class="fa fa-edit"></i>
                          {{'price-list.dashboard.edit' | translate}}</a>
                        <button type="button" class="btn btn-link"
                          (click)="showDeleteConfermationDialog(priceList.id)"><i class="fa fa-trash"></i>
                          {{'price-list.dashboard.delete' | translate}}</button>
                      </ng-template>
                      <button type="button" class="btn btn-link" (click)="duplicatePriceListClickEvent(priceList.id)"><i
                          class="fa fa-copy"></i>
                        {{'price-list.dashboard.duplicate' | translate}}</button>
                      <button type="button" class="btn btn-info btn-fill" (click)="assignPriceListToEventClick(priceList.id)"><i
                          class="fa fa-clipboard"></i>
                        {{'price-list.dashboard.assign' | translate}}</button>
                    </div>
                  </td>
                </tr>
              </ng-container>
              <ng-template #emptyPriceListsTemplate>
                <tr>
                  <td colspan="3" class="text-center">
                    {{'price-list.dashboard.noPriceListsErrorMsg' | translate}}
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<div *ngIf="event.priceLists.length > 1">
  <div class="card">
    <div class="card-body">
      <div class="card-title" style="position: relative;">
        <h4 style="margin-top: 0px;">{{'event.details.assignPriceList' | translate}}
        </h4>
        <div class="scroll-info">
          &rArr;&nbsp;&nbsp;Scroll
        </div>
      </div>
      <div class="table-responsive">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="width: 50%;">{{'event.statistic.deviceUserName' | translate}}</th>
              <th scope="col" style="width: 50%;">{{'event.details.pricelist' | translate}}</th>
            </tr>
          </thead>
          <tbody>
            <ng-container>
              <tr *ngFor="let user of event.deviceUsers; index as i">
                <td>{{user.name}}</td>
                <td>
                  <ng-select [items]="event.priceLists" [(ngModel)]="priceLists[i]" bindLabel="name" bindValue="name">
                  </ng-select>
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
      <div class="row">
        <div class="col">
          <div class="text-right mt-3">
            <button type="button" [disabled]="false" class="btn btn-primary btn-sm" (click)="saveSettings()"><i
                class="fa fa-save"></i>
              {{'assign-category-printer.save' | translate}}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-confirm-dialog-model #confirmDuplicatePriceListDialog (getResult)="getDuplicatePriceListDecision($event)"
  [modalTitle]="'price-list.dashboard.duplicatePriceListWarningTitle' | translate"
  [modalContent]="'price-list.dashboard.duplicatePriceListWarningMsg' | translate"
  [denyText]="'price-list.dashboard.no' | translate" [acceptText]="'price-list.dashboard.yes' | translate">
</app-confirm-dialog-model>
<app-confirm-dialog-model #confirmDeletePriceListDialog (getResult)="getDeletePriceListDecision($event)"
  [modalTitle]="'price-list.dashboard.deletePriceListWarningTitle' | translate"
  [modalContent]="'price-list.dashboard.deletePriceListWarningMsg' | translate"
  [denyText]="'price-list.dashboard.no' | translate" [acceptText]="'price-list.dashboard.yes' | translate">
</app-confirm-dialog-model>
