import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { RouterModule, Routes } from '@angular/router';
import { CoreModule } from './core/core.module';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { APP_VERSION } from './core/constants';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule } from '@auth0/angular-jwt';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AccountModule } from './account/account.module';
import { EventModule } from './event/event.module';
import { PriceListModule } from './price-list/price-list.module';
import { OffersModule } from './offers/offers.module';
import { PrintersModule } from './printers/printers.module';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NotificationModule } from '@progress/kendo-angular-notification';

import 'hammerjs';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule, PanelBarModule } from '@progress/kendo-angular-layout';


export function tokenGetter() {
  return localStorage.getItem('access_token');
}


const routes: Routes = [
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard' }
];

const translationConfig = {
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
};

const toastrConfig = {
  closeButton: true,
  progressBar: true,
  positionClass: 'toast-bottom-left',
  timeOut: 3000
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes),
    HttpClientModule,
    TranslateModule.forRoot(translationConfig),
    ToastrModule.forRoot(toastrConfig),
    BrowserAnimationsModule,
    JwtModule.forRoot({ config: { tokenGetter: tokenGetter, headerName: 'X-Access-Token' } }),
    NgHttpLoaderModule.forRoot(),
    LeafletModule,
    FormsModule,
    ReactiveFormsModule,
    CoreModule,
    AccountModule,
    EventModule,
    PriceListModule,
    OffersModule,
    PrintersModule,
    GridModule,
    InputsModule,
    DropDownsModule,
    DialogsModule,
    NgbModule,
    NotificationModule,
    DropDownsModule,
    ButtonsModule,
    LabelModule,
    LayoutModule,
    PanelBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', `.json?version=${APP_VERSION}`);
}
