import { NgModule } from '@angular/core';
import { OffersComponent } from './offers.component';
import { OffersService } from './shared/offers.service';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { CoreModule } from '../core/core.module';
import { OFFERS_ROUTES } from './offers.routes';
import { RequestOfferComponent } from './request-offer/request-offer.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RequestOffer2Component } from './request-offer2/request-offer2.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { CheckBoxModule, InputsModule } from '@progress/kendo-angular-inputs';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    RouterModule.forChild(OFFERS_ROUTES),
    SharedModule,
    CoreModule,
    NgSelectModule,
    DropDownsModule,
    DateInputsModule,
    LabelModule,
    InputsModule,
    CommonModule,
    CheckBoxModule
  ],
  declarations: [
    OffersComponent,
    RequestOfferComponent,
    RequestOffer2Component
  ],
  providers: [
    OffersService
  ]
})
export class OffersModule { }
