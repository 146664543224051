import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Village } from 'src/app/shared/models/Village';
import { HttpUtils } from 'src/app/shared/http-utils';
import { HttpClient } from '@angular/common/http';
import { URL_BASE } from 'src/app/core/constants';
import { map } from 'rxjs/operators';
import { EventOffer } from 'src/app/shared/models/EventOffer';
import { EventCost } from 'src/app/shared/models/EventCost';
import { Country } from 'src/app/shared/models/Country';
import { PickupPoint } from 'src/app/shared/models/PickupPoint';
import { AdditionalCost } from 'src/app/shared/models/AdditionalCost';
import { EventOfferDraft } from 'src/app/shared/models/EventOfferDraft';
import { DeliveryAddress } from 'src/app/shared/models/DeliveryAddress';

@Injectable({
  providedIn: 'root'
})
export class OffersService {

  constructor(private _http: HttpClient) { }

  /**
  * getVillages
  */
  public getAllVillages(): Observable<Village[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/villages/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Village.fromDto(res)))
    );
  }

  /**
  * getVillages
  */
  public getCountries(): Observable<Country[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/countries/get', { headers }).pipe(
      map((result: any[]) => result.map(res => Country.fromDto(res)))
    );
  }

  /**
   * calculateEventOfferPrice
   */
  public calculateEventOfferPrice(eventOffer: EventOffer, days: number, pickupPointId: number, returnObject: boolean): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/eventOffer/calculatePrice', { eventOffer, days, pickupPointId }, { headers: headers }).pipe(
      map((res: any) => (returnObject ? EventCost.fromDto(res) : EventCost.fromDtoPrice(res))));
  }

  /**
   * insertEventOffer
   */
  public insertEventOffer(eventOffer: EventOffer, emailToUse: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.put(URL_BASE + '/eventOffer/insert',
      { eventOffer, sendEmailToAdmin: true, sendEmailToOrganization: true, emailToUse }, { headers }).pipe(
        map((res: any) => res));
  }

  /**
  * getPickupPoints
  */
  public getPickupPoints(): Observable<PickupPoint[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/pickupPoints/get', { headers }).pipe(
      map((result: any[]) => result.map(res => PickupPoint.fromDto(res)))
    );
  }
  /**
  * getAdditionalCosts
  */
  public getAdditionalCosts(): Observable<AdditionalCost[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/additionalCosts/get', { headers }).pipe(
      map((result: any[]) => result.map(res => AdditionalCost.fromDto(res)))
    );
  }
  /**
  * getAdditionalCostsAssigned
  */
  public getAdditionalCostsAssigned(eventId: string): Observable<any[]> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/admin/additionalCosts/getAssigned', { headers: headers, params: { eventId } }).pipe(
      map((result: any[]) => result)
    );
  }

  /**
   * getClientFinishedEventsCount
   */
  public isUserNew(id: string): Observable<any> {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/event/getFinishedEventsForClient', { headers: headers, params: { id } }).pipe(
      map((result: any[]) => result)
    );
  }

  /**
   * Save Offer Draft
   */
  public saveDraft(eventOfferDraft: EventOfferDraft) {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/draft/save', { eventOfferDraft }, { headers }).pipe(
      map((result: any) => result)
    );
  }

  /**
   * Get Offer Draft of client
   */
  public getDraft() {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/draft/get', { headers: headers }).pipe(
      map((result: EventOfferDraft) => result)
    );
  }

  /**
   * Get All Drafts of a client
   */
  public getAllDrafts() {
    const headers = HttpUtils.createHeaders();
    return this._http.get(URL_BASE + '/drafts/get', { headers: headers }).pipe(
      map((result: EventOfferDraft[]) => result)
    );
  }

  /**
   * Close Draft when Offer is created
   */
  public closeDraft(draftId: number) {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/draft/close', {draftId}, {headers}).pipe(
      map((result: any[]) => result)
    );
  }

  /**
   * Save Delivery Address
   */
  public saveDeliveryAddress(deliveryAddress: any) {
    const headers = HttpUtils.createHeaders();
    return this._http.post(URL_BASE + '/deliveryAddress/save', {deliveryAddress}, {headers}).pipe(
      map((result: DeliveryAddress) => result)
    );
  }
}
